/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        $('body:not(.home):not(.white) .main > div:first-child .panel-grid:first-child').append('<div class="slide-number"><a href="#second-section">Scroll</a><div class="pipe"></div></div>');

        $(window).load(function(){
          $('.slide-number a').click(function(e){
            e.preventDefault();
            $(window).scrollTo($(this).closest('.panel-grid').next(), 500);
          });
        });

        $('a.launch-modal, .launch-modal a').click(function(e) {
          e.preventDefault();
          $($(this).attr('href')).addClass('shown');
          $($(this).attr('href')).removeClass('page-load');
          $('.navbar-toggle').collapse({toggle: false});
          $('.navbar-collapse').trigger('collapse');
          $('html, body').addClass('modal-open');
        });

        $('.dismiss, .dismiss-no-text').click(function() {
          $('#' + $(this).attr('data-modal')).removeClass('shown');
          $('html, body').removeClass('modal-open');
        });

        if ($('body').hasClass('white')) {
          $('header .brand img').attr('src', window.location.origin + '/app/themes/lwsn/assets/images/logo-black.svg');
        }

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        $('.panel-grid').addClass('section');
        
        var slidesAmount = $('.panel-grid.section').length;
        var currentSlide = 1;

        $('.panel-grid:first-child').append('<div class="slide-number"><a>' + currentSlide + '/0' + slidesAmount + '</a><div class="pipe"></div></div>');

        $('#pl-5').fullpage({
          scrollingSpeed: 600,
          easing: 'easeInOutQuart',
          //Scrolling
          scrollBar: true,
          //Design
          controlArrows: false,
          verticalCentered: false,
          sectionsColor : [],
          paddingTop: '',
          paddingBottom: '',
          fixedElements: '',
          responsiveWidth: 0,
          responsiveHeight: 0,

          onLeave: function(index, nextIndex, direction){
            setTimeout(function(){
              if (direction === 'down') {
                currentSlide++;
                $('.slide-number a').text(currentSlide + '/0' + slidesAmount);
              } else {
                currentSlide--;
                $('.slide-number a').text(currentSlide + '/0' + slidesAmount);
              }
            }, 600);
            
          }
        });

        $(window).load(function(){
          $('.slide-number a').click(function(e){
            e.preventDefault();
            $.fn.fullpage.moveSectionDown();
          });
        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
